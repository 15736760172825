import thme

/* Are these styles depreciated?  */
.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Tooltip Customizations for Google Map Pins */
/* Values need to be linked with our Styled-system constants (e.g. primary color, padding, fonts, etc.) */
/* Transitions effects need to be triggered by Javascript and not CSS because the Google parent element of the tooltip has no size. */

.gm-style-iw.gm-style-iw-c {
  font: "Inter", "sans-serif" !important;
  font-weight: 500;
  text-transform: uppercase !important;
  letter-spacing: 0.1em !important;
  font-size: 14px !important;
  padding: 16px 16px 16px 16px !important;
  background-color: #2f85e3 !important;
  color: white !important;
  cursor: auto;
  text-align: center;
  border-radius: 8px;
  opacity: 0.95;
}

.gm-style-iw-d {
  overflow: hidden !important;
}

.gm-style .gm-style-iw-t::after {
  background: #2f85e3;
  opacity: 0.95;
}

.gm-ui-hover-effect {
  display: none !important;
}

/* What's this for? */
@import "react-toggle.scss";
